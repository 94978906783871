import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: "'Roboto Mono', monospace",
        heading: "'Teko', sans-serif",
        monospace: "'Roboto Mono', monospace",
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#0a1919',
        background: '#fff',
        grey: '#2b5055',
        primary: '#f93c2d',
        secondary: '#c52a1e',
        muted: '#f3f5f5',
    },
}